/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import { LANGUAGE_GET, USER_GET, USER_REMEMBER } from '../../config/constants'
import Logger from '../../utils/Logger'
import { cleanLocalStorage, setLocalStorage, getLocalStorage } from '../../utils/StorageLocal'
import { AuthProviderProps, ERole, IAuthContext, ICompleteRegistration, IUser } from './types'
import '../../components/translate/i18n'
import { useTranslation } from 'react-i18next';
import { LOCALE_YUP_BR, LOCALE_YUP_ES, LOCALE_YUP_US, LOCALE_YUP_FR } from '../../assets/Constants'
import { setLocale } from 'yup';
import ToastNotification from '../../components/ToastNotification';
import { externalLogin, login } from '../../services/Auth'
import { dateMaskInverter } from '../../utils/Mask'
import { postCompleteRegistration, postEmailConfirmation, postTermsOfUse } from '../../services/User'
import { IChallenge } from '../../services/Challenges/types'
import { cleanLocalStorageNoExpiration, getLocalStorageNoExpiration } from '../../utils/StorageLocalNoExpiration'
import { Login } from '../../pages/loginAndRegister/types'


const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export function AuthProvider({ children }: Readonly<AuthProviderProps>) {
  const [role, setRole] = useState<ERole[] | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [challenge, setChallenge] = useState<IChallenge | null>(null)
  const [languageSelected, setLanguageSelected] = useState<string | null>(getLocalStorage(LANGUAGE_GET));
  const [completeRegistration, setCompleteRegistration] = useState(false);
  const [usuarioId, setUsuarioId] = useState('');
  const [statusCompleteTermsOfUse, setStatusCompleteTermsOfUse] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [link, setLink] = useState('');
  const [listContents, setListContents] = useState<string[]>([]);

  const [tempTokenSocial, setTempTokenSocial] = useState('');
  const [tempProviderLoginType, setTempProviderLoginType] = useState(0);

  const [tempEmail, setTempEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [typeLogin, setTypeLogin] = useState(0);
  const [tempRememberPassword, setTempRememberPassword] = useState(false);

  useEffect(() => {
    const loadAsyncStorage = () => {
      const storagedUser = getLocalStorage<IUser>(USER_GET);
      const rememberUser = getLocalStorageNoExpiration<Login>(USER_REMEMBER);
      Logger('storage', storagedUser);
      if (storagedUser && storagedUser.Token) {
        updateUser(storagedUser);
        if (!storagedUser.TermosUsoAceito) {
          signOut();
        }
      } else if (rememberUser?.email) {
        signIn(rememberUser.email, rememberUser.password, true, true)
      }
    }
    loadAsyncStorage();
    changeLanguageOnLoad();
  }, [])

  const updateUser = useCallback((user: IUser) => {
    const payload: { role: ERole | ERole[], unique_name: string } = jwt_decode(user?.Token ?? '')
    setRole(typeof payload.role === 'string' ? [payload.role] : payload.role)

    const data = {
      ...user,
      id: payload?.unique_name
    }

    setUser(data);
    setLocalStorage<IUser>(USER_GET, data);
  }, [])

  const signIn = async (email: string, password: string, rememberPassword: boolean, load?: boolean, token2?: string) => {
    try {
      const bodyData = {
        Email: email,
        Password: password
      };
      const response = await login(bodyData);
      if (response.Success) {
        setTypeLogin(1)
        if (response.Data?.Type === 1) {
          setEmail(email);
          navigate(`/email_confirmation/login`)
        } else if (response.Data?.Type === 2) {
          setTempEmail(email)
          setTempPassword(password)
          setTempRememberPassword(rememberPassword)
          setUsuarioId(response.Data?.UserId ?? '')
          setStatusCompleteTermsOfUse(true)
        } else if (response.Data?.Token) {
          const data = {
            ...response.Data,
            rememberPassword,
            TermosUsoAceito: true
          }
          updateUser(data)

          if (token2) {
            navigate('/programas_especiais');
          } else {
            navigate('/home');
          }
        }
      } else if (!load) {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('O campo Usuário e/ou Senha está inválido'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      } else {
        cleanLocalStorageNoExpiration()
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t(`Houve um erro ao realizar o login, tente novamente.`)
      });
    }
  }

  const socialLogin = async (token: string, type: number) => {
    try {
      const bodyData = {
        Token: token,
        ProviderLoginType: type
      };
      const response = await externalLogin(bodyData);
      if (response.Success) {
        setTypeLogin(2)
        if (response.Data.TemporaryId) {
          setCompleteRegistration(true);
          setUsuarioId(response.Data?.TemporaryId)
          setTempTokenSocial(token)
          setTempProviderLoginType(type)
        } else if (response.Data?.Type === 2) {
          setUsuarioId(response.Data?.UserId ?? '')
          setTempTokenSocial(token)
          setTempProviderLoginType(type)
          setStatusCompleteTermsOfUse(true)
        } else {
          const data = {
            ...response.Data,
            rememberPassword: true,
            TermosUsoAceito: true
          }

          updateUser(data)
          navigate('/home');
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao realizar o login, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao realizar o login, tente novamente.')
      });
    }
  }

  const editLanguageUser = (language?: string) => {
    if (language === 'PT') return 1
    if (language === 'EN') return 2
    if (language === 'ES') return 3
    if (language === 'FR') return 4
    return 1
  }

  const completeRegistrationSocialLogin = async (body: ICompleteRegistration) => {
    try {
      const bodyData = {
        BornDate: dateMaskInverter(body.BornDate) || '',
        TemporaryUserId: usuarioId,
        Language: editLanguageUser(languageSelected ?? 'PT')
      };
      const response = await postCompleteRegistration(bodyData);
      if (response.Success) {
        setCompleteRegistration(false);
        socialLogin(tempTokenSocial, tempProviderLoginType)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao completar os dados, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao completar os dados, tente novamente.')
      });
    }
  }

  const completeTermsOfUse = async (TermsOfUseId: string,) => {
    try {
      const bodyData = {
        TermOfUseId: TermsOfUseId,
        UserId: usuarioId
      };
      const response = await postTermsOfUse(bodyData);
      if (response.Success) {
        setStatusCompleteTermsOfUse(false)

        if (typeLogin === 2) {
          socialLogin(tempTokenSocial, tempProviderLoginType)
        } else {
          signIn(tempEmail, tempPassword, tempRememberPassword)
        }

      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao aceitar os termos de uso, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao aceitar os termos de uso, tente novamente.')
      });
    }
  }

  const emailConfirmation = async (code: string, email: string, token?: string) => {
    try {
      const bodyData = {
        Codigo: code,
        Email: email
      };
      const response = await postEmailConfirmation(bodyData);
      if (response.Success) {
        setCompleteRegistration(false);
        const data = {
          ...response.Data,
          rememberPassword: true,
          TermosUsoAceito: true
        }

        updateUser(data)

        if (Number(token?.length) > 0) {
          navigate('/programas_especiais');
        } else {
          navigate('/home');
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao confirmar o código, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao confirmar o código, tente novamente.')
      });
    }
  }

  const signOut = () => {
    setUser(null)
    cleanLocalStorage()
    cleanLocalStorageNoExpiration()
    navigate('/login')
  }

  const setLocaleValidator = async (language: string | null) => {
    if (language === 'PT') {
      setLocale(LOCALE_YUP_BR);
    } else if (language === 'EN') {
      setLocale(LOCALE_YUP_US);
    } else if (language === 'ES') {
      setLocale(LOCALE_YUP_ES);
    } else if (language === 'FR') {
      setLocale(LOCALE_YUP_FR);
    }
  }

  const changeLanguageOnLoad = async () => {
    try {
      const language = await getLocalStorage<string | null>(LANGUAGE_GET)
      if (language) {
        changeLanguage(language);
      }
    } catch (err) {
      console.error("Error", err);
    }
  }

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
      .then(() => {
        setLocaleValidator(language);
        setLanguageSelected(language);
        setLocalStorage(LANGUAGE_GET, language);
      })
      .catch((err) => {
        console.error("Error", err);
      })
  }

  const changeCompleteRegistration = (state: boolean) => {
    setCompleteRegistration(state)
  }

  const refuseCompleteTermsOfUse = () => {
    setStatusCompleteTermsOfUse(false)
    signOut();
  }



  return (
    <AuthContext.Provider
      value={{
        role,
        changeLanguage,
        languageSelected: languageSelected ?? "PT",
        signIn,
        socialLogin,
        user,
        isAuthenticated: (!!user && user.TermosUsoAceito) || false,
        signOut,
        completeRegistration,
        changeCompleteRegistration,
        completeRegistrationSocialLogin,
        completeTermsOfUse,
        refuseCompleteTermsOfUse,
        statusCompleteTermsOfUse,
        emailConfirmation,
        setEmail,
        email,
        challenge,
        setChallenge,
        setMenu,
        menu,
        setLink,
        link,
        setListContents,
        listContents
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
