import styled from 'styled-components'
import { responsiveFont } from '../../../utils/UtilsGlobal'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  @media (max-width: 680px) {
    margin-top: 8px;
  }
`

export const ContainerChallenge = styled.div`
  display: flex;
  height: 100%;
  margin: 16px 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  align-self: center;

  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 8px 0px;
    align-items: center;
    padding: 0px 8px;
    gap: 16px;
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(5, 1fr);
  }
`

export const ContainerRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;


  .boxSelect {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 16px;

    h1 {
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colorsBase.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      margin-left: 8px;
    }

    .switchDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      h5 {
        text-align: left;
        font-size: ${responsiveFont(12)};
        color: ${(props) => props.theme.colorsBase.black};
        font-weight: 400;
        font-family: 'Barlow', Regular;
      }
    }
  }

  .containerInputsRegister {
    width: 100%;
    height: 100%;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .containerButtonsRegister {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .containerButtonsRegisterRight {
    display: flex;
    align-self: flex-end;
  }

  .containerPaggingRegister {
    display: flex;
    align-self: center;
    margin-bottom: -52px;
  }

  @media (max-width: 680px) {
    .containerPaggingRegister {
      margin-bottom: 8px;
    }

    .containerButtonsRegister {
      padding-bottom: 16px;
    }

    .containerButtonsRegisterRight {
      justify-content: space-between;
      width: 66.66%;
      margin-left: 24px;
    }
  }
`

export const CoverStepProgram = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 32px 16px 32px;
  height: 100%;
  width: 100%;

  .title {
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }
  }

  .subTitle {
    line-height: 18px;
    margin-top: 2px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .textError {
    margin-top: 8px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colorsBase.red};
    font-weight: 400;
    font-family: 'Barlow', Medium;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
    padding: 0px;
  }
`

export const BackgroundImageProgram = styled.label`
  margin-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;

   img {
    width: 100%;
    height: 100%;
    border: solid 1px ${(props) => props.theme.colorsBase.gray};
    z-index: 1;
    object-fit: cover; 
   }

   .iconBackground {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px ${(props) => props.theme.colorsBase.gray};
    background-color: ${(props) => props.theme.colorsBase.gray3};

    .iconCam {
      color: ${(props) => props.theme.colorsBase.black};
      width: 24px;
      height: 24px;
      align-self: center;
    }

    .textAdd {
      margin-top: 4px;
      margin-bottom: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-align: left;
      font-size: ${responsiveFont(12)};
      color: ${(props) => props.theme.colors.black};
      font-weight: 400;
      font-family: 'Barlow', Regular;
      
      @media (max-width: 680px) {
        font-size: ${responsiveFont(10)};
      }
      
      @media (min-width: 1600px) {
        font-size: ${responsiveFont(14)};
      }
    }
   }

  @media (max-width: 680px) {
    
  }
`

export const InputFile = styled.input`
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 3;
  outline: none;
  border: none;
  background-color: transparent;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`

export const ButtonImageEditProgram = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border: solid 1px ${(props) => props.theme.colors.secundary};
  height: 40px;
  border-radius: 4px;
  margin-top: 8px;
  cursor: pointer;

  .textButtonEdit {
    margin: auto 8px;
    text-align: center;
    font-size: ${responsiveFont(14)};
    color: ${(props) => props.theme.colors.secundary};
    font-weight: 400;
    font-family: 'Barlow', Medium;
  
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(16)};
    }
  }

  @media (max-width: 680px) {
   
  }
`

export const ContainerSendingInvitations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  .title {
    margin-left: 32px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(20)};
    color: ${(props) => props.theme.colors.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(22)};
    }
  }

  .subTitle {
    margin-left: 32px;
    line-height: 18px;
    margin-top: 2px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .textCenter {
    line-height: 18px;
    text-align: center;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .containerData {
     display: flex;
     width: 100%;
     height: 100%;
     flex-direction: column;
     align-items: center;
     justify-content: center;
  }

  @media (max-width: 680px) {
    .title {
      margin-left: 0px;
      line-height: 16px;
      font-size: ${responsiveFont(14)};
    }
  
    .subTitle {
      margin-left: 0px;
      line-height: 14px;
      font-size: ${responsiveFont(12)};
    }
  }
`

export const ContainerListEmails = styled.div<{ details?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top:  ${(props) => (props.details ? '0px' : '24px')}; 
  padding-bottom:  ${(props) => (props.details ? '12px' : '24px')}; 
  height: 100%;
  width: 100%;

  .title {
    padding-bottom:  ${(props) => (props.details ? '4px' : '2px')}; 
    margin-left:  ${(props) => (props.details ? '0px' : '16px')}; 
    line-height: 18px;
    text-align: left;
    font-size: ${responsiveFont(12)};
    color: ${(props) => props.theme.colors.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(14)};
    }
  }

  .containerList {
    display: flex;
    width: 100%;
    height:   ${(props) => (props.details ? `100%` : '200px')}; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 12px;
    border: solid 1px ${(props) => props.theme.colorsBase.gray15};
    padding: 8px;
 }

  .containerListColumn {
     display: flex;
     width: 100%;
     height: ${(props) => (props.details ? `100%` : '200px')}; 
     flex-direction: column;
     align-items: center;
     justify-content: flex-start;
     overflow-y: auto;

     &::-webkit-scrollbar {
      border-radius: 8px;
      width: 8px;
      background: ${(props) => props.theme.colors.eleven};
      }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colorsBase.gray7};
      border-radius: 8px;
    }
    ::-webkit-scrollbar:vertical { 
      height: 4px;
      border-radius: 8px;
    }
  }

  .itemList {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
  }

  .deleteItemList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
  }

  .textItemList {
    line-height: 18px;
    text-align: left;
    font-size: ${responsiveFont(16)};
    color: ${(props) => props.theme.colorsBase.black3};
    font-weight: 400;
    font-family: 'Barlow', Regular;
    
    @media (min-width: 1600px) {
      font-size: ${responsiveFont(18)};
    }
  }

  .boxMessage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: center;
    background-color: ${(props) => props.theme.colorsBase.gray3};
    width: 300px;
    margin-top: 16px;
    border-radius: 4px;
    padding: 4px;

    .iconMessage {
      color: ${(props) => props.theme.colors.friday};
      font-size: 16px;
      margin-right: 4px;
    }

    h5 {
      text-align: center;
      line-height: 12px;
      font-size: ${responsiveFont(10)};
      color: ${(props) => props.theme.colors.friday};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }
  }


  .boxNoResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;

    .iconNoResults {
      color: ${(props) => props.theme.colors.seventh};
      font-size: 24px;
      margin-bottom: 8px;
    }

    h3 {
      text-align: center;
      line-height: 20px;
      font-size: ${responsiveFont(16)};
      color: ${(props) => props.theme.colorsBase.black3};
      font-weight: 400;
      font-family: 'Barlow', Regular;
    }
  }

  @media (max-width: 680px) {
    padding-bottom: 24px; 

    .containerList {
      border-radius: ${(props) => (props.details ? `12px` : ' 0px')}; 
      border: ${(props) => (props.details ? `solid 1px rgba(107, 107, 107, 1)` : 'none')}; 
      border-top: solid 1px ${(props) => props.theme.colorsBase.gray15};  
      padding: ${(props) => (props.details ? `8px` : '2px 0px 0px 0px')}; 
   }

   .title {
    margin-bottom: 2px;
    margin-left: 8px;
   }
  }
`

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw; 
  height: 100vh;
  overflow:auto; 
  padding: 70px 16px 16px 16px;
  background-color: ${(props) => props.theme.colorsBase.white};
`

export const BoxExcel = styled.label`
display: flex;
flex-direction: column;
align-items: center;
align-self: center;
border-radius: 4px;
position: relative;
padding: 4px;
border: solid 1px #6A3AE7;
margin-top: 12px;

.iconB {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .textAdd {
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: ${responsiveFont(14)};
    color: #6A3AE7;
    font-weight: 500;
    font-family: 'Barlow', Medium;
  }
}
`

export const InputExcel = styled.input`
  position: absolute;
  top: 0;
  width: 308px;
  height: 608px;
  border-radius: 8px;
  z-index: 3;
  outline: none;
  border: none;
  background-color: red;
  display: transparent;

  &[type='file'] {
    display: none;
  }
`