import React, { useState } from 'react';
import * as S from './styles'
import { HeaderProps } from './types';
import { ArrowBack, Facebook, Handshake, Link, MailSharp, OpenInNew, Share, Twitter, WarningAmber, WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TextField, Rating, MenuItem, Select } from '@mui/material'
import { ContainerSponsorRetargeting, ContainerStars } from '../../pages/Challenge/styles';
import ModalCustom from '../ModalCustom'
import ButtonMaterial from '../../components/Button';
import { theme } from '../../styles/theme';
import Modal from '@mui/material/Modal'
import ModalCustomBottom from '../ModalCustomBottom';
import { useIsSm } from '../../hooks/useIsSm';
import ToastNotification from '../../components/ToastNotification';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { postReportChallenge } from '../../services/Challenges';

const ChallengeHeader: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { t } = useTranslation();
  const { mediaAvaliacoes, qtdAvaliacoes, buttonNavigate, title, id, sponsor } = props
  const [openModal, setOpenModal] = useState(false)
  const [openShare, setOpenShare] = useState(false);
  const isSm = useIsSm()
  const navigate = useNavigate()
  const [reasonForTheComplaint, setReasonForTheComplaint] = useState("")
  const [additionalDetailsOfTheComplaint, setAdditionalDetailsOfTheComplaint] = useState("")
  const [loading, setLoading] = useState(false);

  const handleReportChallenge = async () => {
    setLoading(true);
    try {
      if (reasonForTheComplaint === '') {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Selecione o Motivo da Denúncia')
        });
        setLoading(false);
        return;
      }

      if (reasonForTheComplaint === '4' && additionalDetailsOfTheComplaint === '') {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Adicione Detalhes na Denúncia')
        });
        setLoading(false);
        return;
      }

      const bodyData = {
        ReportReason: reasonForTheComplaint,
        Description: additionalDetailsOfTheComplaint
      };
      const response = await postReportChallenge(bodyData, id);
      if (response.Success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('A denúncia foi registrada e será avaliada.')
        });
        setOpenModal(false)
        setReasonForTheComplaint('')
        setAdditionalDetailsOfTheComplaint('')
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao registrar a denúncia, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao registrar a denúncia, tente novamente.')
      });
    }
    setLoading(false);
  };

  const content = () => {
    return (
      <S.Options mobile={isSm} >
        {!isSm && <h3>{t('Compartilhe esse desafio via:')}</h3>}

        <S.ContainerButtonsSocial>
          <button className='buttonSocial' onClick={() => { window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(`https://www.curarsaude.com.br/desafio/${id}`)); setOpenShare(false); return false; }} >
            <Facebook className="iconSocial" />
          </button> 

          <button className='buttonSocial' onClick={() => { window.open('https://twitter.com/intent/tweet?text=%20Gostei%20desse%20desafio%20da%20Curar%20Saúde!%20Dá%20uma%20olhada' + ':%20' + encodeURIComponent(`https://www.curarsaude.com.br/desafio/${id}`)); setOpenShare(false); return false; }}>
            <Twitter className="iconSocial" />
          </button>

          <a className='buttonSocial' target="_blank" onClick={() => setOpenShare(false)} href={`https://api.whatsapp.com/send?text=${t('Gostei desse desafio da Curar Saúde! Dá uma olhada')}: https://www.curarsaude.com.br/desafio/${id}`}>
            <WhatsApp className="iconSocial" />
          </a>

          <a className='buttonSocial' href="mailto:?subject=&body=:%20" onClick={() => { window.open('mailto:?subject=' + encodeURIComponent(`${t('Gostei desse desafio da Curar Saúde! Dá uma olhada')}:`) + '&body=' + encodeURIComponent(`https://www.curarsaude.com.br/desafio/${id}`)); setOpenShare(false); return false; }}>
            <MailSharp className="iconSocial" />
          </a>
        </S.ContainerButtonsSocial>

        <h3>{t('Ou copie o link:')}</h3>

        <S.ContainerLink>
          <div className='boxLink'>
            <Link className="iconLink" />
            <h4>{t(`curarsaude.com.br/desafio/${id}`)}</h4>
          </div>

          <button className='buttonLink' onClick={() => copyToClipboard(id)}>
            <h5>{t('Copiar')}</h5>
          </button>
        </S.ContainerLink>
      </S.Options>
    )
  };

  const copyToClipboard = async (id?: string) => {
    try {
      await navigator.clipboard.writeText(`https://www.curarsaude.com.br/desafio/${id}`);
      setOpenShare(false);
      ToastNotification({
        id: 'success',
        type: 'success',
        message: t('Link copiado!')
      });
    } catch (err) {
      console.error('Error', err);
    }
  };

  const contentComplaint = () => {
    return (
      <>
        <FormControl sx={{ display: 'flex', m: 1, width: isSm ? '100%' : '60%', marginRight: isSm ? '0px' : 'auto', marginLeft: '0px' }}>
          <InputLabel id="demo-simple-select-helper-label">{t('Motivo da Denúncia')}</InputLabel>
          <Select
            MenuProps={{
              MenuListProps: {
                sx: { maxHeight: '200px', maxWidth: '100%', overflowY: 'auto' }
              }
            }}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            variant="standard"
            style={{ display: 'flex', width: '100%' }}
            value={reasonForTheComplaint}
            onChange={(e) => {
              setReasonForTheComplaint(String(e.target.value))
            }}
          >
            <MenuItem value={'1'}>{t('Conteúdo Ofensivo')}</MenuItem>
            <MenuItem value={'2'}>{t('Informação imprecisa')}</MenuItem>
            <MenuItem value={'3'}>{t('Violência ou discurso de ódio')}</MenuItem>
            <MenuItem value={'4'}>{t('Outro')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="outlined-multiline-static"
          label={t('Detalhes Adicionais')}
          multiline
          rows={8}
          defaultValue="Default Value"
          variant="outlined"
          value={additionalDetailsOfTheComplaint}
          style={{ marginTop: '16px', width: '100%', }}
          onChange={(e) => setAdditionalDetailsOfTheComplaint(e.target.value)}
        />

        {isSm &&
          <ButtonMaterial
            style={{ backgroundColor: theme.colors.seventh, width: '100%', marginTop: "8px", marginBottom: '12px' }}
            variant="contained"
            label={t('ENVIAR')}
            loading={loading}
            onClick={handleReportChallenge}
          />
        }
      </>
    )
  };

  return (
    <S.ContainerBack>
      <ContainerStars>
        <div className='divs'>
          <Rating
            name="read-only"
            size="small"
            value={mediaAvaliacoes}
            readOnly
          />
          <h4>{qtdAvaliacoes} {t('Avaliações')}</h4>
        </div>
      </ContainerStars>

      <div>
        <button className='buttonB' onClick={buttonNavigate}>
          <ArrowBack className="iconBackButton" />
          <h5>{t('Voltar')}</h5>
        </button>

        <S.LineTitle>
          <h3>{title}</h3>
        </S.LineTitle>
      </div>

      <S.ContainerBottomBottons active={sponsor}>
        <div className='left'>
          {sponsor &&
            <ContainerSponsorRetargeting active={true}>
              <Handshake className="iconA" />

              <div className='divtext'>
                <h3>{t('Seu patrocínio pode fazer a diferença! Apoie os desafios que você ama e ajude-nos a crescer.')}</h3>

                <button className='divtextlink' onClick={() => navigate('/canal_dos_patrocinadores')}>
                  <h4>{t('Saiba mais sobre Patrocínios')}</h4>
                  <OpenInNew className="iconB" />
                </button>
              </div>
            </ContainerSponsorRetargeting>
          }
        </div>
        <div className='right'>
          <S.BottonToShare onClick={() => setOpenShare(true)}>
            <Share className="i" />
            <h3>{t('Compartilhar Desafio')}</h3>
          </S.BottonToShare>

          <S.BottonReport onClick={() => setOpenModal(true)}>
            <WarningAmber className="i" />
            <h3>{t('Denunciar Desafio')}</h3>
          </S.BottonReport>
        </div>
      </S.ContainerBottomBottons>

      {!isSm ? (
        <Modal
          open={openShare}
          onClose={() => setOpenShare(false)}
        >
          {content()}
        </Modal>
      ) : (
        <ModalCustomBottom
          open={openShare}
          onClose={() => setOpenShare(false)}
          title={t('Compartilhe esse desafio via:')}
          buttonShare={true}
          childrenContent={
            <>
              {content()}
            </>
          }
        />
      )}

      {!isSm ? (
        <ModalCustom
          open={openModal}
          onClose={() => setOpenModal(false)}
          onClick={handleReportChallenge}
          title={t('Denunciar desafio')}
          confirmationButtonText={t('ENVIAR')}
          textDeclineButton={t('CANCELAR')}
          twoButton={false}
          childrenContent={
            <>
              {contentComplaint()}
            </>
          }
        />
      ) : (
        <ModalCustomBottom
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={t('Denunciar desafio')}
          showIcon={false}
          childrenContent={
            <>
              {contentComplaint()}
            </>
          }
        />
      )}
    </S.ContainerBack>
  )
}

export default ChallengeHeader
