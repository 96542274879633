import React from 'react';

import PageContainer from '../../components/Layout/PageContainer';
import PaperContainer from '../../components/Layout/PaperContainer';
import { AntTab, AntTabs } from '../../components/Tabs';
import { theme } from '../../styles/theme';
import HeaderAchievements from '../../components/HeaderAchievements';
import { useTranslation } from 'react-i18next';
import { useIsSm } from '../../hooks/useIsSm';
import {  Star } from '@mui/icons-material';
import ScreenSpecialProgramManagement from './ScreenSpecialProgramManagement';

interface IProps {
  tab: number;
  setNewTab: (newTab: number) => void;
  variant?: "fullWidth" | "standard" | "scrollable";
}

const SpecialProgramManagementPage: React.FC<IProps> = ({ setNewTab, tab, variant }) => {
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <PageContainer>
      <HeaderAchievements
        title={t('Programas Especiais')}
        marginBottom={isSm ? 24 : 0}
        buttonBack={!isSm}
        icon={<Star style={{ color: isSm ? theme.colors.secundary : theme.colorsBase.gray3 }} />}
      />
      <PaperContainer>
        <AntTabs value={tab} onChange={(_, newValue) => setNewTab(newValue)} variant={variant}>
          <AntTab label="EM CONSTRUÇÃO" sx={{ width: isSm ? '33.33vw' : 'none' }} />
          <AntTab label="PENDENTES" sx={{ width: isSm ? '33.33vw' : 'none' }} />
          <AntTab label="ATIVOS" sx={{ width: isSm ? '33.33vw' : 'none' }} />
        </AntTabs>
        <ScreenSpecialProgramManagement tab={tab} />
      </PaperContainer>
    </PageContainer>
  )
}

export default SpecialProgramManagementPage
