import Modal from '@mui/material/Modal'
import { IModalCancelCustomProps } from './types'
import * as S from './styles'
import { ArrowBack, Close, Quiz } from '@mui/icons-material'
import { theme } from '../../styles/theme'
import ButtonMaterial from '../Button'
import { useTranslation } from 'react-i18next';
import HeaderMobile from '../HeaderMobile'
import { ContainerChildren } from '../ModalRegistrationAndChallengeEditing/styles';
import { useIsSm } from '../../hooks/useIsSm';

export default function ModalRegistrationAndQuizEditing(props: Readonly<IModalCancelCustomProps>) {
  const { open, onClose, onCloseBottom, onClick, title, loading = false, childrenContent, buttonsEnabled = true } = props
  const { t } = useTranslation();
  const isSm = useIsSm()

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <>
        {isSm && <HeaderMobile />}
        <S.ContainerModalQuiz>
          <S.LineQuiz>
            {isSm && <Quiz className="i" />}
            <h5>{title}</h5>
          </S.LineQuiz>

          <button className='buttonClose' onClick={onClose}>
            <Close className="icon" />
          </button>

          <ContainerChildren>
            {childrenContent}
          </ContainerChildren>

          {buttonsEnabled && (
            <div className='button'>
              <div >
                {!isSm &&
                  <ButtonMaterial
                    style={{ backgroundColor: theme.colors.seventh, marginRight: '24px' }}
                    variant="contained"
                    label={t('FINALIZAR PERGUNTAS')}
                    onClick={onCloseBottom}
                    loading={loading}
                    disabled={loading}
                  />
                }

                <ButtonMaterial
                  style={{ borderColor: theme.colors.secundary, width: isSm ? '100%' : 'none', marginTop: isSm ? '24px' : '0px' }}
                  variant="outlined"
                  label={t('PRÓXIMA PERGUNTA')}
                  onClick={onClick}
                  disabled={loading}
                />
              </div>

              {isSm &&
                <div className='buttonTop'>
                  <ButtonMaterial
                    style={{ borderColor: theme.colors.secundary, }}
                    variant="outlined"
                    label={t('VOLTAR')}
                    icon={<ArrowBack style={{ fontSize: '24px', marginRight: '8px', color: theme.colors.secundary }} />}
                    onClick={onClose}
                    disabled={loading}
                  />

                  <ButtonMaterial
                    style={{ backgroundColor: theme.colors.seventh, marginLeft: '24px' }}
                    variant="contained"
                    label={t('SALVAR')}
                    onClick={onCloseBottom}
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              }
            </div>
          )}
        </S.ContainerModalQuiz>
      </>
    </Modal>
  )
}
