import React from 'react';
import { Box, Chip, Stack, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Control, Controller } from 'react-hook-form';

import { Content, Title } from '../../../../components/ChallengeInfoPage/styles';
import ModalButton from '../../../../components/ModalButton';
import MediaModal from '../../../../components/ChallengeInfoPage/MediaModal';
import QuizModal from '../../../../components/ChallengeInfoPage/QuizModal';
import DifficultyLevelSelect from '../../../../components/DifficultyLevelSelect';
import CategorySelect from '../../../../components/CategorySelect';
import AgeRatingSelect from '../../../../components/AgeRatingSelect';
import LanguageFlag from '../../../../components/LanguageFlag';

import { IChalengeDetailsQueryResponse } from '../../../../services/ChallengesManagement/Details/types';
import { IChalengeQuizQueryResponse } from '../../../../services/ChallengesDefinition/Quizzes/types';
import { IChalengeManagementApprovalCommand } from '../../../../services/ChallengesManagement/Approvals/types';

import CreateQuizModal from './CreateQuizModal';

interface IPropsEditable {
  control: Control<IChalengeManagementApprovalCommand>,
  editable: true
}

interface IPropsNonEditable {
  control?: Control<IChalengeManagementApprovalCommand>,
  editable?: false
}

type IPropsEditableSwith = IPropsEditable | IPropsNonEditable

type IProps = IPropsEditableSwith & {
  challengeDetail: Omit<IChalengeDetailsQueryResponse, "TimeLine">,
  challengeQuizes: IChalengeQuizQueryResponse[],
  hasQuiz: boolean
}

const TriageItemModal: React.FC<IProps> = ({
  control,
  challengeDetail,
  challengeQuizes,
  editable,
  hasQuiz
}) => (
  <Box
    sx={({ breakpoints }) => ({
      display: "grid",
      gap: 2,
      [breakpoints.up("md")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      [breakpoints.down("md")]: {
        gridTemplateRows: "repeat(2, auto)",
      }
    })}
  >
    <Box
      sx={({ breakpoints }) => ({
        [breakpoints.up("md")]: {
          gridColumn: "span 1"
        },
        [breakpoints.down("md")]: {
          gridRow: "span 1"
        }
      })}
    >
      <Stack width="100%" spacing={1}>
        <Box>
          <Title>Autor(a) do Desafio</Title>
          <Content>{challengeDetail.AuthorName || "N/A"}</Content>
        </Box>
        <Box>
          <Title>Título do Desafio</Title>
          <Content>{challengeDetail.Title || "N/A"}</Content>
        </Box>
        <Box>
          <Title>Idioma</Title>
          <LanguageFlag
            Idioma={challengeDetail.Language}
            fillSpace={false}
            style={{ justifyContent: 'start' }}
          />
        </Box>
        <Box>
          <Title>Link da Fonte</Title>
          <Content>{challengeDetail.ContentSourceUrl || "N/A"}</Content>
        </Box>
        <Box>
          <Title>Observação sobre a Fonte</Title>
          <Content>{challengeDetail.ContentSourceDetails || "N/A"}</Content>
        </Box>
        <Box>
          {editable ? (
            <Controller
              control={control}
              name='command.DifficultyLevel'
              render={({ field, fieldState: { error } }) => (
                <DifficultyLevelSelect
                  variant="standard"
                  label="Nível de Dificuldade"
                  error={!!error?.message}
                  helperText={error?.message ?? " "}
                  {...field}
                />
              )}
            />
          ) : (
            <>
              <Title>Nível de Dificuldade</Title>
              <Content>{challengeDetail.DifficultyLevel || "N/A"}</Content>
            </>
          )}
        </Box>
        <Box>
          {editable ? (
            <Controller
              control={control}
              name='command.CategoryId'
              render={({ field, fieldState: { error } }) => (
                <CategorySelect
                  variant="standard"
                  label="Categoria"
                  error={!!error?.message}
                  helperText={error?.message ?? " "}
                  {...field}
                />
              )}
            />
          ) : (
            <>
              <Title>Categoria</Title>
              <Content>{challengeDetail.Category || "N/A"}</Content>
            </>
          )}
        </Box>
      </Stack>
    </Box>
    <Box
      sx={({ breakpoints }) => ({
        [breakpoints.up("md")]: {
          gridColumn: "span 1"
        },
        [breakpoints.down("md")]: {
          gridRow: "span 1"
        }
      })}
    >
      <Stack width="100%" spacing={1}>
        <Box>
          <Title>Imagem de Capa</Title>
          <img
            style={{
              width: '250px',
              aspectRatio: '125/78',
              objectFit: "cover",
              borderRadius: "4px"
            }}
            src={challengeDetail.CoverImageUrl || "https://unsplash-assets.imgix.net/empty-states/photos.png?auto=format&fit=crop&q=60"}
            alt='Cover'
          />
        </Box>
        <Stack direction="row" spacing={2} pt={1}>
          <Box>
            <Title>Mídia</Title>
            <ModalButton
              variant="outlined"
              size='small'
              startIcon={<Search />}
              buttonChildren="VER MÍDIA"
            >
              {({ open, onClose }) => (
                <MediaModal
                  open={open}
                  onClose={onClose}
                  content={challengeDetail.ContentFileUrl}
                  contentType={challengeDetail.ContentType}
                />
              )}
            </ModalButton>
          </Box>
          <Box>
            <Title>Quiz</Title>

            {editable ? <CreateQuizModal hidden={!editable || hasQuiz} control={control} /> : null}

            {!editable || hasQuiz ? (
              <ModalButton
                size='small'
                variant={hasQuiz ? "outlined" : "contained"}
                color={hasQuiz ? "primary" : "info"}
                startIcon={hasQuiz ? <Search /> : null}
                disabled={!hasQuiz && !editable}
                buttonChildren={hasQuiz ? "VER QUIZ" : "Não Definido"}
              >
                {({ open, onClose }) => <QuizModal open={open} onClose={onClose} data={challengeQuizes} />}
              </ModalButton>
            ) : null}

          </Box>
        </Stack>
        <Box>
          {editable ? (
            <Controller
              control={control}
              name="command.Tags"
              render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                <TextField
                  id="Tags"
                  label="Tags"
                  placeholder='Insira as tags'
                  variant="standard"
                  fullWidth
                  sx={{ pr: 2 }}
                  value={value?.join(", ")}
                  onChange={(e) => onChange(e.target.value?.split(",").map((item) => item.trim()) ?? [])}
                  error={!!error?.message}
                  helperText={error?.message ?? " "}
                  {...field}
                />
              )}
            />
          ) : (
            <>
              <Title>Tags</Title>
              {challengeDetail.Tags.length === 0 ? <Content>N/A</Content> : null}
              <Stack direction='row' spacing={1}>
                {challengeDetail.Tags.map((tag) => <Chip key={tag} label={tag} color="info" size="small" />)}
              </Stack>
            </>
          )}
        </Box>
        <Box>
          {editable ? (
            <Controller
              control={control}
              name="command.EstimatedTime"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Tempo Estimado (em minutos)"
                  variant="standard"
                  fullWidth
                  type='number'
                  sx={{ pr: 2 }}
                  error={!!error?.message}
                  helperText={error?.message ?? " "}
                  {...field}
                />
              )}
            />

          ) : (
            <>
              <Title>Tempo Estimado (em minutos)</Title>
              <Content>{challengeDetail.EstimatedTime} min</Content>
            </>
          )}
        </Box>
        <Box>
          {editable ? (
            <Controller
              control={control}
              name="command.AgeRating"
              render={({ field, fieldState: { error } }) => (
                <AgeRatingSelect
                  fullWidth
                  variant='standard'
                  error={!!error?.message}
                  helperText={error?.message ?? " "}
                  {...field}
                />
              )}
            />
          ) : (
            <>
              <Title>Classificação Indicativa</Title>
              <Content>{challengeDetail.AgeRating || "N/A"}</Content>
            </>
          )}
        </Box>
      </Stack>
    </Box>
  </Box >
);

export default TriageItemModal;
