import React, { useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel, TextField, InputAdornment, FormControlLabel, Radio } from '@mui/material'
import { AddBox, Headphones } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Controller, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { responsiveFont } from '../../../utils/UtilsGlobal';
import { theme } from '../../../styles/theme';
import ModalCustom from '../../ModalCustom';
import { useIsSm } from '../../../hooks/useIsSm';
import Input from '../../../components/Input';
import * as S from './styles'
import { ContainerBottom, ContainerResponse } from '../../../pages/ViewChallenge/styles';
import { BoxResponse } from '../../../pages/EditAndViewChallenge/styles';
import ButtonMaterial from '../../../components/Button';

interface IProps {
  open?: boolean;
  onSubmit: SubmitHandler<IQuizForm>;
  onClose?: () => void;
}

export interface IQuizForm {
  questionType: string,
  status: boolean | null,
  question: string,
  response1: string,
  response2: string,
  response3: string,
  response4: string,
  response5: string,
  trueAnswer: string,
  audio?: File,
}

const schema = yup.object().shape({
  questionType: yup.string().required("Campo obrigatório"),
  status: yup.bool().when('questionType', {
    is: (questionType: string) => questionType === '2',
    then: yup.bool().required("Campo obrigatório"),
    otherwise: yup.bool().nullable()
  }),
  question: yup.string().required("Campo obrigatório"),
  response1: yup.string().when('questionType', {
    is: (questionType: string) => questionType === '1',
    then: yup.string().required("Campo obrigatório").when('trueAnswer', {
      is: (trueAnswer: string) => trueAnswer === '1',
      then: yup.string().min(1, 'Resposta não preenchida')
    })
  }),
  response2: yup.string().when('questionType', {
    is: (questionType: string) => questionType === '1',
    then: yup.string().required("Campo obrigatório").when('trueAnswer', {
      is: (trueAnswer: string) => trueAnswer === '2',
      then: yup.string().min(1, 'Resposta não preenchida')
    })
  }),
  response3: yup.string().when('questionType', {
    is: (questionType: string) => questionType === '1',
    then: yup.string().required("Campo obrigatório").when('trueAnswer', {
      is: (trueAnswer: string) => trueAnswer === '3',
      then: yup.string().min(1, 'Resposta não preenchida')
    })
  }),
  response4: yup.string().when(['questionType', 'trueAnswer'], {
    is: (questionType: string, trueAnswer: string) => questionType === '1' && trueAnswer === '4',
    then: yup.string().min(1, 'Resposta não preenchida')
  }),
  response5: yup.string().when(['questionType', 'trueAnswer'], {
    is: (questionType: string, trueAnswer: string) => questionType === '1' && trueAnswer === '5',
    then: yup.string().min(1, 'Resposta não preenchida')
  }),
  trueAnswer: yup.string().when('questionType', {
    is: (questionType: string) => questionType === '1',
    then: yup.string().required()
  }),
})

const QuizForm: React.FC<IProps> = ({ open = false, onSubmit, onClose }) => {
  const { t } = useTranslation()
  const [questionSent, setQuestionSent] = useState(false)
  const { control, reset, watch, handleSubmit, formState: { isLoading, isSubmitting } } = useForm<IQuizForm>({
    defaultValues: {
      questionType: '1',
      status: null,
      question: '',
      response1: '',
      response2: '',
      response3: '',
      response4: '',
      response5: '',
      trueAnswer: ''
    },
    resolver: yupResolver(schema) as unknown as Resolver<IQuizForm>,
  })

  const questionType = watch('questionType')
  const isSm = useIsSm()
  const [numberOfAlternatives, setNumberOfAlternatives] = useState(3);


  const contentSelectRightC = (bool: boolean) => {
    return (
      <InputAdornment
        position="end"
      >
        <Controller
          control={control}
          name='status'
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel style={{ width: '100%', display: 'flex', margin: '0px' }}
              checked={field.value === bool}
              onChange={() => field.onChange(bool)}
              control={<Radio style={{ color: error?.message ? 'red' : 'info' }} />}
              label={<h1 style={{ textAlign: 'center', fontSize: responsiveFont(10), fontWeight: '500' }}>Alternativa <br /> correta</h1>}

            />
          )} />
      </InputAdornment>
    )
  };

  const contentSelectRight = (numb: number) => {
    return (
      <InputAdornment
        position="end"
      >
        <Controller
          control={control}
          name='trueAnswer'
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel style={{ width: '100%', display: 'flex', margin: '0px' }}
              checked={field.value === String(numb)}
              onClick={() => field.onChange(String(numb))}
              control={<Radio style={{ color: error?.message ? 'red' : 'info' }} />}
              label={<h1 style={{ textAlign: 'center', fontSize: responsiveFont(10), fontWeight: '500' }}>Alternativa <br /> correta</h1>}
            />
          )}
        />
      </InputAdornment>
    )
  };

  return (
    <ModalCustom
      open={open}
      loading={isLoading || isSubmitting}
      title={t('Perguntas')}
      style={{ padding: isSm ? '8px' : '24px' }}
      styleLine={{ backgroundColor: 'transparent' }}
      confirmationButtonText={questionSent ? t('ADICIONAR') : t('SALVAR')}
      textDeclineButton={t('CANCELAR')}
      onClose={() => {
        reset();
        setQuestionSent(false);
        onClose?.();
      }}
      onClick={questionSent
        ? () => setQuestionSent(false)
        : handleSubmit(async (data, e) => {
          await onSubmit(data, e)
          setQuestionSent(true)
          reset()
        }, console.error)
      }
      childrenContent={
        questionSent ? (
          <S.TitleQuestionSent>{t('Pergunta salva, deseja adicionar mais uma pergunta?')}</S.TitleQuestionSent>
        ) : (
          <>
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Controller
                control={control}
                name='audio'
                render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                  <S.BoxMediaAudio htmlFor="selecao-arquivo" style={{ border: `solid 1px ${error?.message ? theme.colorsBase.red : theme.colors.seventh}` }}>
                    <div className='iconBackground'>
                      <Headphones className="iconMic" />
                      {value?.name ? <h5 className='textAdd'>{t('Selecionado')}</h5> : <h5 className='textAdd'>{t('Adicionar')}</h5>}
                    </div>

                    <S.InputAudio
                      id="selecao-arquivo"
                      onChange={(e) => onChange(e.target.files?.[0])}
                      type="file"
                      accept="audio/*"
                      {...field}
                    />
                  </S.BoxMediaAudio>
                )}
              />

              <Controller
                control={control}
                name='questionType'
                render={({ field }) => (
                  <FormControl sx={{ m: 1, width: '350px' }}>
                    <InputLabel id="demo-simple-select-helper-label">{t('Tipo de Pergunta')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      variant="standard"
                      style={{ display: 'flex', width: '100%' }}
                      {...field}
                    >
                      <MenuItem value={'1'}>{t('Alternativa')}</MenuItem>
                      <MenuItem value={'2'}>{t('Verdadeiro ou Falso')}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <Controller
              control={control}
              name='question'
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="outlined-multiline-static"
                  label={t('Pergunta')}
                  multiline
                  rows={2}
                  defaultValue="Default Value"
                  variant="outlined"
                  style={{ width: '100%', display: 'flex', marginTop: '16px' }}
                  error={!!error?.message}
                  helperText={t(error?.message ?? "")}
                  {...field}
                />
              )}
            />

            {questionType === '2' ? (
              <ContainerResponse>
                <BoxResponse>
                  <h5 className='textNumber'>1.</h5>
                  <Input
                    label={t('Alternativa')}
                    type="pergunta"
                    value={t('Verdadeiro')}
                    size="small"
                    fullWidth
                    variant="filled"
                    style={{ width: '100%', display: 'flex' }}
                    InputProps={{
                      endAdornment: (contentSelectRightC(true)),
                    }}
                    disabled
                  />
                </BoxResponse>

                <BoxResponse>
                  <h5 className='textNumber'>2.</h5>
                  <Input
                    label={t('Alternativa')}
                    type="pergunta"
                    value={t('Falso')}
                    size="small"
                    fullWidth
                    variant="filled"
                    style={{ width: '100%', display: 'flex' }}
                    InputProps={{
                      endAdornment: (contentSelectRightC(false)),
                    }}
                    disabled
                  />
                </BoxResponse>
              </ContainerResponse>
            ) : (
              <ContainerResponse>
                <Controller
                  control={control}
                  name='response1'
                  render={({ field, fieldState: { error } }) => (
                    <BoxResponse>
                      <h5 className='textNumber'>1.</h5>
                      <Input
                        label={t('Alternativa')}
                        type="pergunta"
                        size="small"
                        fullWidth
                        variant="filled"
                        style={{ width: '100%', display: 'flex' }}
                        InputProps={{
                          endAdornment: (contentSelectRight(1)),
                        }}
                        {...field}
                        error={!!error?.message}
                      />
                    </BoxResponse>
                  )}
                />

                <Controller
                  control={control}
                  name='response2'
                  render={({ field, fieldState: { error } }) => (
                    <S.BoxResponse>
                      <h5 className='textNumber'>2.</h5>
                      <Input
                        label={t('Alternativa')}
                        type="pergunta"
                        size="small"
                        fullWidth
                        variant="filled"
                        style={{ width: '100%', display: 'flex' }}
                        InputProps={{
                          endAdornment: (contentSelectRight(2)),
                        }}
                        {...field}
                        error={!!error?.message}
                      />
                    </S.BoxResponse>
                  )}
                />

                <Controller
                  control={control}
                  name='response3'
                  render={({ field, fieldState: { error } }) => (
                    <S.BoxResponse>
                      <h5 className='textNumber'>3.</h5>
                      <Input
                        label={t('Alternativa')}
                        type="pergunta"
                        size="small"
                        fullWidth
                        variant="filled"
                        style={{ width: '100%', display: 'flex' }}
                        InputProps={{
                          endAdornment: (contentSelectRight(3)),
                        }}
                        {...field}
                        error={!!error?.message}
                      />
                    </S.BoxResponse>
                  )}
                />

                {numberOfAlternatives >= 4 &&
                  <Controller
                    control={control}
                    name='response4'
                    render={({ field, fieldState: { error } }) => (
                      <S.BoxResponse>
                        <h5 className='textNumber'>4.</h5>
                        <Input
                          label={t('Alternativa')}
                          type="pergunta"
                          size="small"
                          fullWidth
                          variant="filled"
                          style={{ width: '100%', display: 'flex' }}
                          InputProps={{
                            endAdornment: (contentSelectRight(4)),
                          }}
                          {...field}
                          error={!!error?.message}
                        />
                      </S.BoxResponse>
                    )}
                  />
                }

                {numberOfAlternatives >= 5 &&
                  <Controller
                    control={control}
                    name='response5'
                    render={({ field, fieldState: { error } }) => (
                      <S.BoxResponse>
                        <h5 className='textNumber'>5.</h5>
                        <Input
                          label={t('Alternativa')}
                          type="pergunta"
                          size="small"
                          fullWidth
                          variant="filled"
                          style={{ width: '100%', display: 'flex' }}
                          InputProps={{
                            endAdornment: (contentSelectRight(5)),
                          }}
                          {...field}
                          error={!!error?.message}
                        />
                      </S.BoxResponse>
                    )}
                  />
                }
              </ContainerResponse>
            )}

            <ContainerBottom>
              <ButtonMaterial
                style={{ border: `solid 1px ${theme.colorsBase.gray3}`, marginTop: '16px', color: theme.colors.friday, textTransform: 'none' }}
                variant="outlined"
                color='info'
                label={t("Adicionar Alternativa")}
                icon={<AddBox style={{ fontSize: '24px', marginRight: '4px', color: theme.colors.friday }} />}
                onClick={() => setNumberOfAlternatives(numberOfAlternatives + 1)}
                disabled={numberOfAlternatives >= 5}
              />

              <div className='divLine' />

            </ContainerBottom>
          </>
        )
      }
    />
  )
}

export default QuizForm;