import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer'
import { theme } from '../../styles/theme';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeaderAuthentication from '../../components/HeaderAuthentication';
import { InputAdornment, Tooltip } from '@mui/material'
import { CalendarMonth, CheckBox, CheckBoxOutlineBlank, Info, } from '@mui/icons-material';
import { Login, LoginError, Registration, RegistrationError } from './types';
import ButtonMaterial from '../../components/Button';
import Input from '../../components/Input';
import ToastNotification from '../../components/ToastNotification';
import { registerValidation, signInValidation } from './validations';
import { useAuth } from '../../contexts/Auth';
import * as S from './styles'
import CompleteRegistration from '../../components/CompleteRegistration';
import TermsOfUse from '../../components/TermsOfUse';
import { dateMask, dateMaskInverter, } from '../../utils/Mask';
import { postUser } from '../../services/User';
import { ButtonHidePassword } from '../../components/ButtonHidePassword';
import { useIsSm } from '../../hooks/useIsSm';
import SocialLogin from '../../components/SocialLogin';
import { removeSpaces } from '../../utils/UtilsGlobal';
import { setLocalStorageNoExpiration } from '../../utils/StorageLocalNoExpiration';
import { USER_REMEMBER } from '../../config/constants';

const RecoverPassword: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState<Login>(new Login());
  const [loginError, setLoginError] = useState<LoginError>(new LoginError());
  const [rememberPassword, setRememberPassword] = useState(false);
  const [headerActive, setHeaderActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { signIn, completeRegistration, statusCompleteTermsOfUse, setEmail, languageSelected } = useAuth();
  const [registration, setRegistration] = useState<Registration>(new Registration());
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError());
  const [showPasswordRegistration, setShowPasswordRegistration] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [modalStatusTermsOfUse, setModalStatusTermsOfUse] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const isSm = useIsSm()
  const { token, token2 } = useParams();

  const [eightCharacters, setEightCharacters] = useState(false);
  const [capitalLetter, setCapitalLetter] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [spaceCharacter, setSpaceCharacter] = useState(false);


  useEffect(() => {
    if (token && token !== '0') {
      setHeaderActive(true)
    }
  }, [token]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      if (rememberPassword) {
        setLocalStorageNoExpiration<Login>(USER_REMEMBER, { email: login.email, password: login.password });
      }

      let countError = 0;

      const validation = await signInValidation(login);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setLoginError(validation as LoginError);
        setLoading(false);
        return;
      }

      await signIn(login.email, login.password, rememberPassword, false, token2);
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao realizar o login, tente novamente.')
      });
    }
    setLoading(false);
  };

  const editLanguageUser = (language?: string) => {
    if (language === 'PT') return 1
    if (language === 'EN') return 2
    if (language === 'ES') return 3
    if (language === 'FR') return 4
    return 1
  }

  const handleRegistration = async () => {
    setLoading(true);
    try {
      let countError = 0;

      const validation = await registerValidation(registration);

      if (typeof validation !== 'boolean') {
        countError++;
      }
      if (countError > 0) {
        setRegistrationError(validation as RegistrationError);
        setLoading(false);
        return;
      }

      const bodyData = {
        Name: registration.name,
        Email: registration.email,
        Password: registration.password,
        PasswordConfirmation: registration.confirmPassword,
        BornDate: dateMaskInverter(registration.dateOfBirth),
        InviteCode: token ? (token === '0' ? token2 : token) : '',
        Language: editLanguageUser(languageSelected ?? 'PT')
      };

      const response = await postUser(bodyData);
      if (response.Success) {
        setEmail(registration.email);
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Cadastro realizado com sucesso, confirme seu e-mail para continuar')
        });
        if (token2) {
          navigate(`/email_confirmation/${token2}`)
        } else {
          navigate(`/email_confirmation`)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao se cadastrar, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao se cadastrar, tente novamente.')
      });
    }
    setLoading(false);
  };

  const onEnterDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (headerActive) {
        handleRegistration();
      } else {
        handleLogin();
      }
    }
  };


  const regExpCapitalLowerCase = (password: string) => {
    let i = 0;
    if (password?.length === 0) {
      return false;
    }
    while (i < password?.length) {
      const character = password.charAt(i);
      if (character === character.toLowerCase()) {
        return true;
      }
      i++;
    }
    return false;
  };
  const regExpCapitalUpperCase = (password: string) => {
    let i = 0;
    if (password?.length === 0) {
      return false;
    }
    while (i < password?.length) {
      const character = password.charAt(i);
      if (character === character.toUpperCase()) {
        return true;
      }
      i++;
    }
    return false;
  };


  const validatePassword = (password: string) => {
    if (password?.length > 0) {
      validatePasswordPhases(password)
    }

    return password;
  };

  const validatePasswordPhases = (password: string) => {
    const regExpSpecialChar = /[^a-zA-Z0-9]/;
    const regExpNumber = /\d/;

    if (regExpSpecialChar.test(password)) {
      setSpaceCharacter(true);
    } else {
      setSpaceCharacter(false);
    }

    if (regExpNumber.test(password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (regExpCapitalUpperCase(password)) {
      setCapitalLetter(true);
    } else {
      setCapitalLetter(false);
    }

    if (regExpCapitalLowerCase(password)) {
      setLowerCase(true);
    } else {
      setLowerCase(false);
    }

    if (password?.length >= 8) {
      setEightCharacters(true);
    } else {
      setEightCharacters(false);
    }
  };

  return (
    <>
      <S.Container>
        <HeaderAuthentication />

        <S.ContainerContent>
          <S.LineHeader register={headerActive}>
            <S.ButtonHeader disabled={loading} active={!headerActive} onClick={() => setHeaderActive(false)}>
              <h5>{t('ENTRAR')}</h5>
            </S.ButtonHeader>

            <S.ButtonHeader disabled={loading} active={headerActive} onClick={() => setHeaderActive(true)}>
              <h5>{t('CADASTRAR')}</h5>
            </S.ButtonHeader>
          </S.LineHeader>

          {!headerActive ? (
            <>
              <div className='login'>
                <Input
                  label={t('E-mail')}
                  autoComplete="off"
                  type="fantasyName"
                  value={login.email}
                  onChange={(e) => setLogin({ ...login, email: removeSpaces(e.target.value) })}
                  size="small"
                  fullWidth
                  error={loginError.email?.length > 0}
                  helperText={loginError.email}
                  onClick={() => setLoginError({ ...loginError, email: '' })}
                  variant="filled"
                  onKeyPress={onEnterDown}
                />

                <Input
                  type={showPassword ? 'text' : 'password'}
                  label={t('Senha')}
                  autoComplete="off"
                  size="small"
                  value={login.password}
                  onChange={(e) => setLogin({ ...login, password: removeSpaces(e.target.value) })}
                  error={loginError.password?.length > 0}
                  helperText={t(loginError.password)}
                  onClick={() => setLoginError({ ...loginError, password: '' })}
                  sx={{
                    marginTop: 2,
                  }}
                  InputProps={{
                    endAdornment: (
                      <ButtonHidePassword password={showPassword} onClick={() => setShowPassword((showPassword) => !showPassword)} />
                    ),
                  }}
                  fullWidth
                  style={{ marginTop: '16px' }}
                  variant="filled"
                  onKeyPress={onEnterDown}
                />

                <div className='belowTheInput'>
                  <button className='recoverPassword' onClick={() => navigate(`/recover_password`)}>{t('Esqueci minha senha')}</button>

                  <div className='rememberPassword'>
                    <button onClick={() => setRememberPassword(!rememberPassword)}>
                      {rememberPassword ? <CheckBox className="iTrue" /> : <CheckBoxOutlineBlank className="i" />}
                      <h5>{t('Continuar conectado')}</h5>
                    </button>
                  </div>
                </div>
              </div>

              <ButtonMaterial
                style={{ backgroundColor: theme.colors.seventh, width: isSm ? '100%' : '70%' }}
                variant="contained"
                label={t("ENTRAR")}
                onClick={handleLogin}
                loading={loading}
              />
            </>
          ) : (
            <div className='register'>
              <div className='containerVerticalOrLandscape'>
                <div className='verticalOrLandscape'>
                  <div className='divResponsivenessLeft'>
                    <Input
                      label={t('Nome')}
                      autoComplete="off"
                      type="name"
                      value={registration.name}
                      onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
                      size="small"
                      fullWidth
                      error={registrationError.name?.length > 0}
                      helperText={registrationError.name}
                      onClick={() => setRegistrationError({ ...registrationError, name: '' })}
                      variant="filled"
                      onKeyPress={onEnterDown}
                    />
                  </div>

                  <div className='divResponsivenessRight'>
                    <Input
                      label={t('Data de Nascimento')}
                      autoComplete="off"
                      value={registration.dateOfBirth}
                      onChange={(e) => setRegistration({ ...registration, dateOfBirth: dateMask(e.target.value) })}
                      size="small"
                      fullWidth
                      error={registrationError.dateOfBirth?.length > 0}
                      helperText={t(registrationError.dateOfBirth)}
                      onClick={() => setRegistrationError({ ...registrationError, dateOfBirth: '' })}
                      variant="filled"
                      onKeyPress={onEnterDown}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            <CalendarMonth fill={theme.colors.nine} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <div className='verticalOrLandscape'>
                  <div className='divResponsivenessCenter'>
                    <Input
                      label={t('E-mail')}
                      autoComplete="off"
                      type="emails"
                      value={registration.email}
                      onChange={(e) => setRegistration({ ...registration, email: removeSpaces(e.target.value) })}
                      size="small"
                      fullWidth
                      error={registrationError.email?.length > 0}
                      helperText={registrationError.email}
                      onClick={() => setRegistrationError({ ...registrationError, email: '' })}
                      variant="filled"
                      onKeyPress={onEnterDown}
                    />
                  </div>
                </div>

                <div className='verticalOrLandscape'>
                  <div className='divResponsivenessLeft'>
                    <Input
                      type={showPasswordRegistration ? 'text' : 'password'}
                      label={t('Senha')}
                      autoComplete="off"
                      size="small"
                      value={registration.password}
                      onChange={(e) => setRegistration({ ...registration, password: validatePassword(e.target.value) })}
                      error={registrationError.password?.length > 0}
                      helperText={t(registrationError.password)}
                      onClick={() => setRegistrationError({ ...registrationError, password: '' })}
                      InputProps={{
                        endAdornment: (
                          <ButtonHidePassword password={showPasswordRegistration} onClick={() => setShowPasswordRegistration((showPassword) => !showPassword)} />
                        ),
                      }}
                      fullWidth
                      variant="filled"
                      onKeyPress={onEnterDown}
                    />

                    <S.ContainerPasswordValidation>
                      <h1 className='titlePassword'>{t('Sua senha deve conter:')}</h1>

                      <div className='boxPassword'>
                        <S.TextValidationPassword active={eightCharacters}>{t('• No mínimo 8 caracteres')}</S.TextValidationPassword>
                        <S.TextValidationPassword active={capitalLetter}>{t('• Pelo menos uma letra MAIÚSCULA')}</S.TextValidationPassword>
                        <S.TextValidationPassword active={lowerCase}>{t('• Pelo menos uma letra minúscula')}</S.TextValidationPassword>
                        <S.TextValidationPassword active={number}>{t('• Pelo menos um número')}</S.TextValidationPassword>
                        <S.TextValidationPassword active={spaceCharacter}>{t('• Pelo menos um caractere especial (*, -, #, $, @)')}</S.TextValidationPassword>
                      </div>
                    </S.ContainerPasswordValidation>
                  </div>

                  <div className='divResponsivenessRight'>
                    <Input
                      type={showConfirmPassword ? 'text' : 'password'}
                      label={t('Confirmar Senha')}
                      size="small"
                      autoComplete="off"
                      value={registration.confirmPassword}
                      onChange={(e) => setRegistration({ ...registration, confirmPassword: e.target.value })}
                      error={registrationError.confirmPassword?.length > 0}
                      helperText={t(registrationError.confirmPassword)}
                      onClick={() => setRegistrationError({ ...registrationError, confirmPassword: '' })}
                      InputProps={{
                        endAdornment: (
                          <ButtonHidePassword password={showConfirmPassword} onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)} />
                        ),
                      }}
                      fullWidth
                      variant="filled"
                      onKeyPress={onEnterDown}
                    />

                    <S.ContainerPasswordValidation>
                      <h1 className='titlePassword'>{t('Deve ser igual à senha criada.')}</h1>
                    </S.ContainerPasswordValidation>
                  </div>
                </div>
              </div>

              <div className='terms'>
                <div className='containerAcceptTerms'>
                  <button className='buttonClickTerms' onClick={() => setTermsAccepted(!termsAccepted)}>
                    {termsAccepted ? <CheckBox className="iTrue" /> : <CheckBoxOutlineBlank className="i" />}
                  </button>

                  <div className='boxTerms'>
                    <h5>{t('Ao continuar você estará concordando com os nossos')}</h5>

                    <button className='buttonTerms' onClick={() => setModalStatusTermsOfUse(true)}>
                      <h4>{t('Termos de Uso')}</h4>
                    </button>
                  </div>
                </div>

                <Tooltip enterTouchDelay={1} title={<h3 style={{ fontSize: 12, fontWeight: 'normal' }}>{t('Só é permitido o cadastro de pessoas maiores de idade. Caso seja menor, peça para um responsável se cadastrar e adicioná-lo como dependente.')}</h3>} placement="right-start">
                  <Info className="icon" />
                </Tooltip>

                <ButtonMaterial
                  style={{ backgroundColor: termsAccepted ? theme.colors.seventh : theme.colorsBase.gray3, width: '100%' }}
                  variant="contained"
                  label={t("CADASTRAR")}
                  onClick={handleRegistration}
                  loading={loading}
                  disable={!termsAccepted}
                />
              </div>
            </div>
          )}

          <S.Line>
            <h5>{t('Ou')}</h5>
          </S.Line>

          <S.ContainerSocialLoginButton>
            <SocialLogin />
          </S.ContainerSocialLoginButton>
        </S.ContainerContent>


        <div style={{ display: 'flex', width: '100%', flex: 1, alignItems: 'flex-end' }}>
          <Footer />
        </div>
      </S.Container >

      <TermsOfUse open={statusCompleteTermsOfUse || modalStatusTermsOfUse} register={modalStatusTermsOfUse} onClick={() => setModalStatusTermsOfUse(false)} />
      <CompleteRegistration open={completeRegistration} />
    </>
  )
}

export default RecoverPassword
