import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import ToastNotification from '../../../components/ToastNotification';
import ViewError from '../../../components/ViewError';
import { useIsSm } from '../../../hooks/useIsSm';
import { Pagination } from '@mui/material';
import SearchBar from '../../../components/SearchBar';
import { ICardSpecialProgram, ISpecialProgram } from './types';
import { Pages } from '../../../pages/ViewChallenge/styles';
import ChallengeItemDesktop from '../../../components/ChallengeItemDesktop';
import { useNavigate } from 'react-router-dom';
import { getSpecialProgram } from '../../../services/SpecialProgram';
import ModalSpecialProgram from './ModalSpecialProgram';
import Loading from '../../../components/Loading';
import { getLocalStorage } from '../../../utils/StorageLocal';
import { IUser } from '../../../contexts/Auth/types';
import { USER_GET } from '../../../config/constants';

interface IProps {
  update: boolean;
  tab: number;
}

const ScreenUnderConstruction: React.FC<IProps> = ({ update, tab }) => {
  const { t } = useTranslation();
  const isSm = useIsSm();
  const [value, setValue] = useState('');
  const [specialProgramList, setSpecialProgramList] = useState<ICardSpecialProgram[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [larguraDaTela, setLarguraDaTela] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [tabNumber, setTabNumber] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTabNumber(tab)
    retrieveSpecialProgram(1, true, tab);
  }, [update, tab, isSm]);

  const onEnterSearch = (event: { key: string }) => {
    if (event.key === 'Enter') {
      retrieveSpecialProgram(1, true, tabNumber);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setLarguraDaTela(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = async () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollY + windowHeight >= documentHeight - 100) {
        if ((currentPage <= (totalPages || 0)) && isSm) {
          await retrieveSpecialProgram(currentPage + 1, false, tabNumber);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, totalPages, isSm]);

  const numberElements = () => {
    if (larguraDaTela > 800 && larguraDaTela < 1600) {
      return 3;
    } else if (larguraDaTela > 1600 && larguraDaTela < 2000) {
      return 4;
    } else if (larguraDaTela > 2000) {
      return 5;
    }
    return 3;
  };

  function getStatusText(status: number): 'approved' | 'rejected' | 'under_review' {
    switch (status) {
      case 5:
        return 'approved';
      case 7:
        return 'rejected';
      case 3:
        return 'under_review';
      default:
        return 'under_review'; // Padrão para qualquer outro valor não mapeado
    }
  }

  function convertSpecialProgram(program: ISpecialProgram): ICardSpecialProgram {
    return {
      language: program.Language.toString(), // Convertendo para string conforme a interface ICardSpecialProgram
      image: program?.Image,
      averagePerformance: program?.AverageRate, // Tipos podem precisar de ajustes dependendo do uso
      tags: program?.Tags, // Pode precisar de ajustes dependendo do uso
      description: program?.Description,
      emailList: program?.EmailList, // Pode precisar de ajustes dependendo do uso
      id: program?.Id,
      title: program?.Title,
      coverImageUrl: program?.Image, // Ou outro campo de imagem se necessário
      status: getStatusText(program.Status), // Função para converter o número de status para o texto correspondente
      Challenges: program?.Challenges,
      attempts: String(program?.Attempts)
    };
  }

  const retrieveSpecialProgram = async (page: number = 1, clear: boolean = false, tabGet: number) => {
    setLoading(true)
    try {
      if (clear) {
        setSpecialProgramList([]);
      }
      const storagedUser = getLocalStorage<IUser>(USER_GET);

      const bodyData = {
        PageNumber: page,
        PageSize: isSm ? 64 : numberElements(),
        Name: value,
        Type: typeSearchData(tabGet),
        ContentAdditionCompleted: (tabGet === 1 || tabGet === 2) ? "true" : "false",
        GetChallenges: true,
        AuthorId: storagedUser?.id
      };

      const response = await getSpecialProgram(bodyData);

      if (response.Success) {
        const responseAPI: ISpecialProgram[] = response.Data
        const mapperSpecialPrograms = responseAPI.map(convertSpecialProgram)

        setCurrentPage(page);
        if (page === 1 && response.TotalPages) {
          setTotalPages(response.TotalPages);
        }
        if (clear) {
          setSpecialProgramList(mapperSpecialPrograms);
        } else if (response.Data.length > 1) {
          const novaArray = specialProgramList.concat(mapperSpecialPrograms);
          setSpecialProgramList(novaArray);
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Houve um erro ao buscar os programas, tente novamente.'),
          errorMessage: response.Message,
          errors: response.Errors,
        });
      }
    } catch (ex) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os programas, tente novamente.'),
      });
    }
    setLoading(false)
  };

  const typeSearchData = (type: number) => ({
    0: 1,
    1: 1,
    2: 5,
  }[type]);

  const renderViewError = (type: number) => ({
    0: t('Ainda não há programas cadastrados'),
    1: t('Ainda não há programas pendentes'),
    2: t('Ainda não há programas ativos'),
  }[type]);

  const handleItemClick = async (id: string) => {
    try {
      const programDetails = specialProgramList.find(item => item.id === id);

      if (tabNumber === 1) {
        setOpen(true)
        setId(id)
      } else {
        navigate(`/detalhes_do_programa_especial/${id}`, { state: { specialProgram: programDetails } });
      }

    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Houve um erro ao buscar os detalhes do programa, tente novamente.'),
      });
    }
  };

  return (
    <S.Container>
      <SearchBar
        value={value}
        setValue={(e) => setValue(e)}
        onEnterSearch={(e) => onEnterSearch(e)}
        placeholder={t('Busque o programa especial')}
        filterButton={false}
        style={{ padding: 0, width: isSm ? '100%' : '80%', marginRight: 'auto' }}
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          {specialProgramList.length > 0 ? (
            <>
              <S.ContainerChallenge>
                {specialProgramList.map((item) => (
                  <ChallengeItemDesktop
                    key={item.id}
                    onClick={() => handleItemClick(item.id)}
                    typeChallenge={10}
                    item={{
                      CoverImageUrl: item.coverImageUrl,
                      Language: item.language,
                      Title: item.title,
                      Id: item.id.toString(),
                    }}
                    greenButton={tabNumber === 1}
                    labelButton={tabNumber === 1 ? "Enviar programa especial" : "ver programa especial"}
                  />
                ))}
              </S.ContainerChallenge>

              {!isSm && (
                <Pages>
                  <Pagination
                    showFirstButton
                    showLastButton
                    count={totalPages}
                    page={currentPage}
                    color="primary"
                    onChange={(e, p) => {
                      e.preventDefault();
                      e.stopPropagation();
                      retrieveSpecialProgram(p, true, tabNumber);
                    }}
                  />
                </Pages>
              )}
            </>
          ) : (
            <ViewError message={renderViewError(tabNumber)} />
          )}
        </>
      )}

      <ModalSpecialProgram open={open} setOpen={setOpen} id={id} update={() => retrieveSpecialProgram(1, true, tabNumber)} />
    </S.Container>
  );
};

export default ScreenUnderConstruction;
